<script>
import MilestoneService from "@/services/MilestoneService";
import VMilestoneForm from "@/components/Forms/VMilestoneForm";
import VFilters from "@/components/Search/VFilters";
import AppCfg from "@/mixins/AppCfg";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VMilestones",
    mixins: [AppCfg, MGConsts],
    components: { VMilestoneForm, VFilters },
    data() {
        return {
            loading: true,
            milestones: [],
            meta: {},
            showCreateForm: false,
            context: {}
        };
    },
    created() {
        this.initSearch();
        this.fetchData();
        this.$apiEnableWatcher();
    },
    methods: {
        initSearch() {
            this.apiSearchFilter = {
                search: "",
                status: ""
            };
        },
        /**
         * Fetch all milestones
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const { data } = await MilestoneService.GET(params);
                this.milestones = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fe ", e);
            }
            this.loading = false;
        },
        /**
         * Set context and show the form
         * @param {} ctx
         */
        setContext(ctx) {
            this.context = ctx;
            this.showCreateForm = true;
        },
        /**
         * Executed when a milestone is added/edited (success)
         */
        closeRefresh() {
            this.context = { empty: true };
            this.showCreateForm = false;
            this.fetchData();
        }
    }
};
</script>

<template>
    <div class="v-milestones">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        v-if="!loading"
                        :search="true"
                        v-model="apiSearchFilter.search"
                        :enable-filters="true"
                        @filter-view-change="$handleFilterView($event)"
                        @reset-search="initSearch"
                    >
                        <template slot="default">
                            <div class="col-4">
                                <FormulateInput
                                    v-model="apiSearchFilter.status"
                                    :placeholder="$t('milestones.status')"
                                    type="fselect"
                                    :options="mgGeneralActive"
                                />
                            </div>
                        </template>
                    </v-filters>
                </div>
                <div class="col-12">
                    <card body-classes="px-0">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <h3 class="px-4 mb-4">
                                {{ $t("milestones.h_milestone_manager") }}
                            </h3>
                            <base-button
                                @click="
                                    setContext({
                                        empty: true
                                    })
                                "
                                size="sm"
                                type="primary"
                                class="mx-4 mb-4"
                                ><i class="far fa-plus"></i>
                                {{ $t("milestones.create_new") }}</base-button
                            >
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="milestones.length">
                            <div class="table-responsive">
                                <v-table class="table table-mg">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t("milestones.tbl_hdr_name")
                                            }}</v-th>
                                            <v-th class="text-center">{{
                                                $t("milestones.tbl_hdr_status")
                                            }}</v-th>
                                            <v-th>{{
                                                $t(
                                                    "milestones.tbl_hdr_internal_note"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right"></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="m in milestones"
                                            :key="m.id"
                                        >
                                            <v-td>{{ m.name }}</v-td>
                                            <v-td class="text-center">
                                                <span
                                                    :class="{
                                                        'text-success':
                                                            m.status === 1,
                                                        'text-danger':
                                                            m.status !== 1
                                                    }"
                                                    >{{ m.status_name }}</span
                                                >
                                            </v-td>
                                            <v-td>
                                                <v-newlines :text="m.comment" />
                                            </v-td>
                                            <v-td class="text-right">
                                                <i
                                                    class="far fa-fw fa-lg fa-pencil m-click"
                                                    @click="setContext(m)"
                                                ></i>
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="mt-4 mx-4 alert alert-warning">
                                {{ $t("milestones.no_milestones_found") }}
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-milestone-form
                @close-refresh="closeRefresh"
                @close="showCreateForm = false"
                :context="context"
                v-if="showCreateForm"
            />
        </portal>
    </div>
</template>
