<script>
import { mapGetters } from "vuex";
import Cloner from "@/libraries/Cloner";
import MGConsts from "@/mixins/MGConsts";
import MilestoneService from "@/services/MilestoneService";

export default {
    name: "VMilestoneForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            }
        }
    },
    mixins: [MGConsts],
    data() {
        return {
            editing: false,
            loading: true,
            errors: null,
            milestone: {
                id: null,
                name: null,
                comment: null,
                status: null
            }
        };
    },
    async created() {
        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);

        if (!this.context.empty) {
            // Edit
            this.editing = true;
            this.milestone = Cloner.pickClone(this.context, [
                "id",
                "created",
                "modified",
                "name",
                "comment",
                "status"
            ]);
        } else {
            this.milestone.status = "1";
        }

        this.loading = false;
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            try {
                if (this.editing) {
                    const r = await MilestoneService.PUT(this.milestone);
                } else {
                    const r = await MilestoneService.POST(this.milestone);
                }
                this.$notify({
                    message: this.$t("milestone.notification_milestone_saved"),
                    timeout: 5000,
                    icon: "far fa-check",
                    type: "success"
                });
                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Delete
         */
        deleteEntity() {
            if (this.editing) {
                this.$swal({
                    icon: "warning",
                    text: this.$t("milestones.delete_are_you_sure"),
                    showConfirmButton: true,
                    showCancelButton: true
                }).then(async confirmed => {
                    if (confirmed.isConfirmed) {
                        try {
                            const r = await MilestoneService.DELETE(
                                this.milestone.id
                            );
                            this.$emit("close-refresh");
                        } catch (e) {
                            if ("errors" in e.response.data) {
                                this.errors = e.response.data.errors;
                            } else {
                                this.$swal({
                                    icon: "error",
                                    title: this.$t("error_cannot_delete"),
                                    text: this.$t("unknown_error")
                                });
                            }
                        }
                    }
                });
            }
        }
    }
};
</script>

<template>
    <modal
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="budget-modal"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("milestones.edit") }}
            </template>
            <template v-else>
                {{ $t("milestones.add") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm
                v-model="milestone"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="fselect"
                            :options="mgGeneralActive"
                            name="status"
                            :placeholder="$t('milestone.placeholder_status')"
                            validation="required"
                            :validation-name="$t('milestone.status')"
                            :help="$t('milestone.help_status')"
                        />
                    </div>

                    <div class="col-12">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('milestone.name')"
                            :help="$t('milestone.help_name')"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="comment"
                            type="textarea"
                            :label="$t('milestone.internal_note')"
                            :help="$t('milestone.help_internal_note')"
                        />
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-6 col-sm-12 col-xs-12 text-left">
                        <base-button
                            type="danger"
                            v-if="editing"
                            :outline="true"
                            @click="deleteEntity()"
                            ><i class="far fa-trash"></i
                        ></base-button>
                    </div>
                    <div class="col-md-6 col-sm-12 col-xs-12 text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style></style>
