var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-activities"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"}),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.groups.length)?_vm._l((_vm.groups),function(group){return _c('div',{key:group.id,staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h3',{staticClass:"px-4 mb-4"},[_vm._v(_vm._s(group.name))]),_c('div',[_c('a',{staticClass:"btn btn-sm btn-primary btn-outline mx-3 mb-4",attrs:{"href":"/v1/activity-categories?csv=1"}},[_c('i',{staticClass:"far fa-file-csv mr-1"}),_vm._v(" "+_vm._s(_vm.$t("activities.export_csv")))]),(_vm.ACL_RW && group.editable == 1)?_c('base-button',{staticClass:"mr-4 mb-4",attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.setContext(
                                            {
                                                empty: true,
                                                activity_category_id:
                                                    group.id
                                            },
                                            group.algo_slug
                                        )}}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("activities.create_new")))]):_vm._e()],1)]),_c('div',{staticClass:"px-4 mb-4"},[_c('p',[_c('i',{staticClass:"far fa-info mr-2"}),_vm._v(" "+_vm._s(_vm.$t( "activities.help_text_preference_fields" ))+" ")])]),_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-sm table-mg"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("activities.name")))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("activities.status")))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("activities.inv_setup_MEG")))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t( "activities.inv_setup_PROJECTS" )))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("activities.inv_setup_MES")))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("activities.number_of_tasks")))]),_vm._l((_vm.langs),function(lang,idx){return _c('v-th',{key:idx,staticClass:"text-left"},[_vm._v(" "+_vm._s(lang)+" ")])}),(_vm.ACL_RW)?_c('v-th'):_vm._e()],2)],1),_c('v-tbody',_vm._l((group.activities),function(act){return _c('v-tr',{key:act.id},[_c('v-td',{class:act.active == 0
                                                    ? 'text-danger'
                                                    : ''},[_vm._v(" "+_vm._s(act.name))]),_c('v-td',{staticClass:"text-center",class:act.active == 0
                                                    ? 'text-danger'
                                                    : ''},[_vm._v(_vm._s(act.active_name))]),_c('v-td',{staticClass:"text-center"},[_c('i',{staticClass:"far",class:{
                                                    'fa-check text-success':
                                                        act.pref_meg === 1,
                                                    'fa-times text-warning':
                                                        act.pref_meg === 0
                                                }})]),_c('v-td',{staticClass:"text-center"},[_c('i',{staticClass:"far",class:{
                                                    'fa-check text-success':
                                                        act.pref_projects ===
                                                        1,
                                                    'fa-times text-warning':
                                                        act.pref_projects ===
                                                        0
                                                }})]),_c('v-td',{staticClass:"text-center"},[_c('i',{staticClass:"far",class:{
                                                    'fa-check text-success':
                                                        act.pref_mes === 1,
                                                    'fa-times text-warning':
                                                        act.pref_mes === 0
                                                }})]),_c('v-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$n(act.task_count))+" ")]),_vm._l((_vm.langs),function(lang,idx){return _c('v-td',{key:idx},[(
                                                    'available_translations' in
                                                        act &&
                                                        act.available_translations.includes(
                                                            idx
                                                        )
                                                )?[_vm._v(" "+_vm._s(act .invoice_translations[ idx ])+" ")]:_c('i',{staticClass:"far fa-times text-muted"})],2)}),(_vm.ACL_RW)?_c('v-td',{staticClass:"text-right"},[(group.editable)?_c('base-dropdown',{attrs:{"menu-on-right":true,"has-toggle":false}},[_c('template',{slot:"title-container"},[_c('i',{staticClass:"far fa-ellipsis-h fa-lg"})]),_c('div',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.setContext(
                                                            act,
                                                            group.algo_slug
                                                        )}}},[_c('i',{staticClass:"far fa-fw fa-pencil"}),_vm._v(" "+_vm._s(_vm.$t( "activities.action_edit" ))+" ")]),(
                                                        act.task_count == 0
                                                    )?_c('div',{staticClass:"dropdown-item text-danger",on:{"click":function($event){return _vm.handleDeleteActivity(
                                                            act
                                                        )}}},[_c('i',{staticClass:"far fa-fw fa-trash"}),_vm._v(" "+_vm._s(_vm.$t( "activities.action_delete" ))+" ")]):_vm._e()],2):_c('i',{staticClass:"far fa-fw fa-ban"})],1):_vm._e()],2)}),1)],1)],1)])],1)}):_vm._e()],2)]),_c('portal',{attrs:{"to":"modals"}},[(_vm.editModal)?_c('v-activity-form',{attrs:{"context":_vm.editContext,"slug-context":_vm.slugContext},on:{"close":function($event){_vm.editModal = false},"close-refresh":_vm.handleChange}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }