<script>
import ActivityService from "@/services/ActivityService";
import ActivityCategoryService from "@/services/ActivityCategoryService";
import AppCfg from "@/mixins/AppCfg";
import Cloner from "@/libraries/Cloner";

export default {
    name: "VActivityForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
            description: "Context",
        },
        slugContext: {
            type: String,
            description:
                "algo_slug from category to identify system, internal, external etc.",
        },
    },
    mixins: [AppCfg],
    data() {
        return {
            loading: false,
            editing: false,
            activity: {
                id: null,
                name: null,
                active: "1",
                activity_category_id: null,
                invoice_translations: null,
                pref_meg: null,
                pref_projects: null,
                pref_mes: null
            },
            categories: [],
            errors: {},
            activeOptions: [
                {
                    value: "1",
                    label: this.$t("activity.active"),
                },
                {
                    value: "0",
                    label: this.$t("activity.inactive"),
                },
            ],
        };
    },
    computed: {
        langs: function () {
            return this.appCfgRead("Activities.langs");
        },
    },
    async created() {
        await this.fetchCategories();

        if (!this.context.empty) {
            this.editing = true;
            this.activity = Cloner.pickClone(this.context, [
                "id",
                "name",
                "active",
                "activity_category_id",
                "pref_meg",
                "pref_projects",
                "pref_mes"
            ]);
            this.activity.invoice_translations = [];
            this.activity.invoice_translations[0] = Cloner.clone(
                this.context.invoice_translations
            );
        } else {
            this.activity.activity_category_id =
                this.context.activity_category_id;
            this.activity.pref_meg = false;
            this.activity.pref_projects = false;
            this.activity.pref_mes = false;
        }
    },
    methods: {
        /**
         * Save form
         */
        async saveForm() {
            let submit = Cloner.clone(this.activity);

            if (submit.invoice_translations != null) {
                submit.invoice_translations = submit.invoice_translations[0];
            }

            // Convert potential bools to ints.
            submit.pref_meg = submit.pref_meg ? 1 : 0;
            submit.pref_projects = submit.pref_projects ? 1 : 0;
            submit.pref_mes = submit.pref_mes ? 1 : 0;

            try {
                if (this.editing) {
                    const r = await ActivityService.PUT(submit);
                    this.$emit("save", r.data.data);
                } else {
                    const r = await ActivityService.POST(submit);
                    this.$emit("save", r.data.data);
                }

                this.$emit("close-refresh");
            } catch (e) {
                this.errors = e.response.data.errors;
            }
        },
        /**
         * Fetch categories
         */
        async fetchCategories() {
            try {
                this.loading = true;
                const r = await ActivityCategoryService.GET_dropdown({
                    editable: 1,
                });
                this.categories = r.data.data;
            } catch (e) {
                console.log("fetchCategories excetpion", e);
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <modal
        :show="true"
        @close="$emit('close')"
        class="activity-modal"
        :loading="loading"
    >
        <template slot="header">
            <template v-if="editing">
                {{ $t("activity.edit_activity") }}
            </template>
            <template v-else>
                {{ $t("activity.create_activity") }}
            </template>
        </template>

        <template slot="default">
            <FormulateForm
                v-model="activity"
                @submit="saveForm"
                :errors="errors"
                :debounce="100"
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            name="activity_category_id"
                            type="select"
                            :options="categories"
                            :label="$t('activity.category')"
                            :validation-name="$t('activity.category')"
                            :placeholder="$t('activity.placeholder_category')"
                            validation="required"
                            :disabled="true"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="name"
                            type="text"
                            :label="$t('activity.name')"
                            :validation-name="$t('activity.name')"
                            :placeholder="$t('activity.placeholder_name')"
                            validation="required"
                        />
                    </div>
                    <div class="col-12">
                        <FormulateInput
                            name="active"
                            type="select"
                            :options="activeOptions"
                            :label="$t('activity.active')"
                            :validation-name="$t('activity.active')"
                            :placeholder="$t('activity.placeholder_active')"
                            validation="required"
                        />
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col">
                                <div class="form-control-label mb-2">
                                    {{ $t("activity.preference") }}
                                </div>
                                <div class="">
                                    <input
                                        type="checkbox"
                                        v-model="activity.pref_meg"
                                    />
                                    {{ $t("activity.checkbox_pref_MEG") }}
                                </div>

                                <div class="">
                                    <input
                                        type="checkbox"
                                        v-model="activity.pref_projects"
                                    />
                                    {{ $t("activity.checkbox_pref_PROJECTS") }}
                                </div>

                                <div class="">
                                    <input
                                        type="checkbox"
                                        v-model="activity.pref_mes"
                                    />
                                    {{ $t("activity.checkbox_pref_MES") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <h3 v-if="slugContext == 'customer'">
                            {{ $t("activities.invoice_translations") }}
                        </h3>
                        <h3 v-else-if="slugContext == 'internal'">
                            {{ $t("activities.translations") }}
                        </h3>
                        <FormulateInput
                            type="group"
                            name="invoice_translations"
                        >
                            <FormulateInput
                                v-for="(lang, index) in langs"
                                :key="index"
                                :label="
                                    slugContext == 'customer'
                                        ? $t(
                                              'activities.invoice_text_in_LANG',
                                              {
                                                  lang: lang,
                                              }
                                          )
                                        : $t('activities.text_in_LANG', {
                                              lang: lang,
                                          })
                                "
                                :name="index"
                            />
                        </FormulateInput>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col text-right">
                        <base-button
                            type="secondary"
                            :outline="true"
                            @click="$emit('close')"
                            >{{ $t("cancel") }}</base-button
                        >
                        <base-button
                            type="primary"
                            :loading="loading"
                            native-type="submit"
                            >{{ $t("save") }}</base-button
                        >
                    </div>
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style>
</style>
