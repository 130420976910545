import Http from "@/libraries/Http"; 

export default {
    apiVersion: 'v1', 
    RESTroute: 'activity-categories',

    /** 
     * Get all resources 
     * GET /resource
     */
    async GET(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}`, {params: params}); 
    }, 
     
    async GET_dropdown(params = {}) {
        return Http.get(`/${this.apiVersion}/${this.RESTroute}/dropdown`, {params: params}); 
    }, 
    
}
