<script>
import VPriceRoleForm from "@/components/Forms/VPriceRoleForm";
import PriceRoleService from "@/services/PriceRoleService";
import Messages from "@/mixins/Messages";

export default {
    name: "RolePrices",
    components: { VPriceRoleForm },
    mixins: [Messages],
    data() {
        return {
            loading: false,
            roles: [],
            currencies: [],
            priceRoleContext: null,
            showPriceModal: false,
        };
    },
    async created() {
        if (!this.$aclGuard(this.$ACL.TIMEREG_MGMT)) {
            return;
        }

        this.ACL_RW = this.$aclCan(this.$ACL.TIMEREG_MGMT);

        await this.fetchData();
        console.log(this.appCfg);
    },
    methods: {
        roleSaved(role) {
            this.$msgSuccess(
                this.$t("price_roles.new_price_role_has_been_added")
            );

            let idx = this.roles.findIndex((r) => {
                return r.id == role.id ? true : false;
            });

            if (idx < 0) {
                this.roles.unshift(role);
            } else {
                this.$set(this.roles, idx, role);
            }

            this.showPriceModal = false;
        },
        async fetchData() {
            try {
                this.loading = false;
                const r = await PriceRoleService.GET();
                this.roles = r.data.data;
                this.currencies = r.data.meta.currencies;
            } catch (e) {
                console.log("fetchData exception", e);
            }
            this.loading = false;
        },
        setPriceRoleContext(ctx) {
            this.priceRoleContext = ctx;
            this.showPriceModal = true;
        },
        handleDelete(role) {
            this.$swal({
                icon: "warning",
                text: this.$t("price_role.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true,
            }).then(async (confirmed) => {
                if (confirmed.isConfirmed) {
                    if (await this.deletePriceRole(role)) {
                        this.$notify({
                            message: this.$t(
                                "price_role.notify_price_role_deleted"
                            ),
                            timeout: 5000,
                            icon: "far fa-check",
                            type: "success",
                        });
                    }
                }
            });
        },
        async deletePriceRole(role) {
            // RUN DELETE ACTION
            // Find the task
            try {
                this.loading = true;
                const r = await PriceRoleService.DELETE(role.id);
                this.fetchData();
                return true;
            } catch (e) {
                this.$handleExceptionError(
                    e,
                    this.$t("price_role.cannot_delete_a_price_role")
                );
            }

            this.loading = false;
            return false;
        },
    },
};
</script>

<template>
    <div class="v-activities">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <h3 class="px-4 mb-4">
                                {{ $t("price_roles.price_roles") }}
                            </h3>
                            <base-button
                                size="sm"
                                type="primary"
                                class="mx-4 mb-4"
                                @click="setPriceRoleContext({ empty: true })"
                                ><i class="far fa-plus"></i>
                                {{ $t("price_roles.create_new") }}</base-button
                            >
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <div
                            class="table-responsive"
                            v-else-if="roles.length > 0"
                        >
                            <v-table class="table table-sm table-mg">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("price_roles.modified")
                                        }}</v-th>
                                        <v-th>{{
                                            $t("price_roles.name")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t("price_roles.usage_count")
                                        }}</v-th>

                                        <v-th
                                            v-for="(c, index) in currencies"
                                            :key="index"
                                            class="text-right"
                                        >
                                            {{
                                                $t(
                                                    "price_roles.hour_rate_in_X",
                                                    { currency: c }
                                                )
                                            }}
                                        </v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="role in roles" :key="role.id">
                                        <v-td>{{
                                            $d(new Date(role.modified), "short")
                                        }}</v-td>
                                        <v-td>{{ role.role_name }}</v-td>
                                        <v-td class="text-right">{{
                                            role.usage_count
                                        }}</v-td>
                                        <v-td
                                            v-for="(
                                                price, currency
                                            ) in role.prices"
                                            :key="currency"
                                            class="text-right"
                                        >
                                            <template v-if="price > 0">
                                                {{
                                                    $n(price, {
                                                        style: "currency",
                                                        currency: currency,
                                                        currencyDisplay: "code",
                                                    })
                                                }}
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td class="text-right">
                                            <base-dropdown
                                                :menu-on-right="true"
                                                :has-toggle="false"
                                            >
                                                <template slot="title-container"
                                                    ><i
                                                        class="far fa-ellipsis-h fa-lg"
                                                    ></i
                                                ></template>
                                                <div
                                                    class="dropdown-item"
                                                    @click="
                                                        setPriceRoleContext(
                                                            role
                                                        )
                                                    "
                                                >
                                                    <i
                                                        class="far fa-fw fa-pencil"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "price_role.action_edit"
                                                        )
                                                    }}
                                                </div>
                                                <div
                                                    class="dropdown-item text-danger"
                                                    v-if="role.usage_count == 0"
                                                    @click="handleDelete(role)"
                                                >
                                                    <i
                                                        class="far fa-fw fa-trash"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "price_role.action_delete"
                                                        )
                                                    }}
                                                </div>
                                            </base-dropdown>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                        <div v-else class="my-4 mx-4 alert alert-warning">
                            {{ $t("price_roles.no_price_roles_found") }}
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <v-price-role-form
                v-if="showPriceModal"
                :context="priceRoleContext"
                @save="roleSaved"
                @close="showPriceModal = false"
            />
        </portal>
    </div>
</template>

<style>
</style>