var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-milestones"},[_c('base-header',{staticClass:"pb-1",attrs:{"type":""}},[_c('bread-bag')],1),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[(!_vm.loading)?_c('v-filters',{attrs:{"search":true,"enable-filters":true},on:{"filter-view-change":function($event){return _vm.$handleFilterView($event)},"reset-search":_vm.initSearch},model:{value:(_vm.apiSearchFilter.search),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "search", $$v)},expression:"apiSearchFilter.search"}},[_c('template',{slot:"default"},[_c('div',{staticClass:"col-4"},[_c('FormulateInput',{attrs:{"placeholder":_vm.$t('milestones.status'),"type":"fselect","options":_vm.mgGeneralActive},model:{value:(_vm.apiSearchFilter.status),callback:function ($$v) {_vm.$set(_vm.apiSearchFilter, "status", $$v)},expression:"apiSearchFilter.status"}})],1)])],2):_vm._e()],1),_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h3',{staticClass:"px-4 mb-4"},[_vm._v(" "+_vm._s(_vm.$t("milestones.h_milestone_manager"))+" ")]),_c('base-button',{staticClass:"mx-4 mb-4",attrs:{"size":"sm","type":"primary"},on:{"click":function($event){return _vm.setContext({
                                    empty: true
                                })}}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("milestones.create_new")))])],1),(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):(_vm.milestones.length)?[_c('div',{staticClass:"table-responsive"},[_c('v-table',{staticClass:"table table-mg"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("milestones.tbl_hdr_name")))]),_c('v-th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("milestones.tbl_hdr_status")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t( "milestones.tbl_hdr_internal_note" )))]),_c('v-th',{staticClass:"text-right"})],1)],1),_c('v-tbody',_vm._l((_vm.milestones),function(m){return _c('v-tr',{key:m.id},[_c('v-td',[_vm._v(_vm._s(m.name))]),_c('v-td',{staticClass:"text-center"},[_c('span',{class:{
                                                    'text-success':
                                                        m.status === 1,
                                                    'text-danger':
                                                        m.status !== 1
                                                }},[_vm._v(_vm._s(m.status_name))])]),_c('v-td',[_c('v-newlines',{attrs:{"text":m.comment}})],1),_c('v-td',{staticClass:"text-right"},[_c('i',{staticClass:"far fa-fw fa-lg fa-pencil m-click",on:{"click":function($event){return _vm.setContext(m)}}})])],1)}),1)],1)],1),_c('div',{staticClass:"paginator"},[_c('base-pagination',{attrs:{"page-count":_vm.meta.pagination.total_pages,"per-page":_vm.meta.pagination.per_page,"value":_vm.meta.pagination.current_page},on:{"input":function($event){return _vm.$handlePagination($event)}}})],1)]:[_c('div',{staticClass:"mt-4 mx-4 alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("milestones.no_milestones_found"))+" ")])]],2)],1)])]),_c('portal',{attrs:{"to":"modals"}},[(_vm.showCreateForm)?_c('v-milestone-form',{attrs:{"context":_vm.context},on:{"close-refresh":_vm.closeRefresh,"close":function($event){_vm.showCreateForm = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }