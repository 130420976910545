<script>
import Cloner from "@/libraries/Cloner";
import AppCfg from "@/mixins/AppCfg";
import PriceRoleService from "@/services/PriceRoleService";
import CurrencyService from "@/services/CurrencyService";
export default {
    name: "VPriceRoleForm",
    props: {
        context: {
            type: Object,
            default: () => {
                empty: true;
            },
        },
    },
    mixins: [AppCfg],
    data() {
        return {
            editing: false,
            loading: false,
            processing: false,
            errors: null,
            currencies: [],
            priceRole: {
                id: null,
                role_name: null,
                prices: null,
            },
        };
    },
    computed: {
        mainCurrency: function () {
            if (this.currencies.length == 0 || this.loading == true) {
                return "";
            }

            let main = this.appCfgRead("PriceRoles.required_currency");
            return this.currencies[
                this.currencies.findIndex((curr) => curr == main)
            ];
        },
        groupErrors: function () {
            if (this.errors && "prices" in this.errors) {
                let errors = {};
                for (const k in this.errors.prices) {
                    errors[`0.${k}`] = this.errors.prices[k];
                }

                return errors;
            }

            return {};
        },
    },
    async created() {
        await this.fetchCurrencies();

        if (!this.context.empty) {
            this.editing = true;
            this.priceRole = Cloner.pickClone(this.context, [
                "id",
                "role_name",
            ]);

            this.priceRole.prices = [];
            this.priceRole.prices[0] = Cloner.clone(this.context.prices);
        }

        this.loading = false;
    },
    methods: {
        async saveForm() {
            let submit = Cloner.clone(this.priceRole);
            submit.prices = submit.prices[0];

            try {
                this.processing = true;

                let r;

                if (this.editing) {
                    r = await PriceRoleService.PUT(submit);
                } else {
                    r = await PriceRoleService.POST(submit);
                }

                this.$emit("save", r.data.data);
            } catch (e) {
                console.log("saveForm exception", e);
                if ("errors" in e.response.data) {
                    this.errors = e.response.data.errors;
                } else {
                    this.errors = null;
                }
            }
        },
        async fetchCurrencies(loading = false) {
            try {
                this.loading = true;
                const r = await CurrencyService.GET_activeCurrencies();
                this.currencies = r.data.data;
                this.priceRole.prices = [];
                this.priceRole.prices.push({});
                // Create placeholders
                this.currencies.forEach((c) => {
                    this.priceRole.prices[0][c] = "";
                });
            } catch (e) {
                console.log("fetchCurrencies exception", e);
            }

            this.loading = loading;
        },
    },
};
</script>

<template>
    <modal
        :loading="loading"
        :show="true"
        @close="$emit('close')"
        class="currency-modal"
    >
        <template slot="header">
            <template v-if="editing">{{
                $t("price_roles.edit_price_role")
            }}</template>
            <template v-else>{{ $t("price_roles.edit_price_role") }}</template>
        </template>
        <template slot="default">
            <FormulateForm
                v-model="priceRole"
                @submit="saveForm"
                :errors="errors"
            >
                <div class="row">
                    <div class="col-12">
                        <FormulateInput
                            type="text"
                            name="role_name"
                            validation="required"
                            :validation-name="$t('price_role.name')"
                            :label="$t('price_role.name')"
                        />
                    </div>

                    <div class="col-12"><hr class="mt-0 mb-4" /></div>

                    <div class="col-12">
                        
                        <FormulateInput
                            type="group"
                            name="prices"
                            :group-errors="groupErrors"
                        >
                            <FormulateInput
                                v-for="(c, index) in currencies"
                                :key="index"
                                :name="c"
                                type="groupdecimal"
                                :precision="2"
                                align="right"
                                :append="c"
                                :label="
                                    $t('price_role.hour_rate_in_X', {
                                        currency: c,
                                    })
                                "
                                :validation-name="$t('price_role.hour_rate')"
                                :validation="
                                    c == mainCurrency ? 'required' : ''
                                "
                            />
                        </FormulateInput>
                    </div>
                </div>

                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        type="primary"
                        :loading="loading"
                        native-type="submit"
                        >{{ $t("save") }}</base-button
                    >
                </div>
            </FormulateForm>
        </template>
    </modal>
</template>

<style lang="scss">
</style>
